import React, { useState } from 'react';
import Navbar from './components/NavBar';
import Footer from './components/Footer';

import aboutus2 from '../src/images/aboutus2.png';
import whyUs1 from '../src/images/whyUs1.png';
import whyUs2 from '../src/images/whyUs2.png';
import whyUs3 from '../src/images/whyUs3.png';
import whyUs4 from '../src/images/whyUs4.png';

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="border border-gray-300 rounded-lg mb-2 px-6">
      <div
        className="flex justify-between items-center p-4 cursor-pointer"
        onClick={toggleOpen}
      >
        <p className="inter text-xl font-light">Q. {question}</p>
        <button className="text-5xl font-thin">{isOpen ? '-' : '+'}</button>
      </div>
      {/* {isOpen && <div className="p-4 border-t border-gray-300">{answer}</div>} */}
      {isOpen && <div className="inter text-xl font-normal p-4 border-gray-300">{answer}</div>}
    </div>
  );
};

const About = () => {
  const faqData = [
    {
      question: 'Can I book a trip directly?',
      answer: 'Yes, you can book a trip directly through our website or by calling us.',
    },
    {
      question: 'How can I access the store?',
      answer: 'Our store is conveniently located at the South Terminal of YVR Airport. Take advantage of the complimentary shuttle bus from the Main Terminal to reach us, and avoid any delivery or airport fees!',
    },
    {
      question: 'What if I want to arrive at Abbotsford Airport?',
      answer: 'No worries, we deliver the car to Abbotsford Airport',
    },
    {
      question: 'What if I want to pick the car up at downtown Vancouver?',
      answer: 'We have a pickup point in downtown Vancouver for your convenience.',
    },
    {
      question: 'What can I do if I want to return the car in a different province?',
      answer: 'Unfortunately, as a local startup, we currently only allow car returns within the Vancouver area.',
    },
  ];

  return (
    <>
      <div className='background-colour'>
        <Navbar />
 
<div className='max-w-6xl pt-24 mx-auto flex flex-col justify-center'>
        <section className="py-16 px-8">
          <div className="container mx-auto">
            <p className="md:text-5xl text-4xl font-light fontColour1 mb-5 md:mb-10">WHY US?</p>
            <div className="flex flex-col-reverse justify-center items-center md:flex-row">
              <div className="w-full md:w-1/2 px-4">
                <h1 className="text-3xl md:text-4xl font-semibold text-gray-900 leading-tight my-4">
                  Budget-friendly rental<br /> cars with 100% <br /> customer satisfaction
                </h1>
                <p className="text-lg mb-2">
                  At Kouseki Auto Rental, we offer affordable rental cars that fit your budget.
                </p>
                <h2 className="text-lg">
                  Whether you need a compact car or a minivan, our fleet meets every need. We promise 100% customer satisfaction to make your rental experience easy and worry-free
                </h2>
              </div>

              <div className="w-full md:w-1/2 px-4 mt-8 md:mt-0">
                <img
                  src={aboutus2}
                  alt=""
                  className="rounded-lg shadow-lg"
                />
              </div>
            </div>
          </div>
        </section>

{/* Why Us Section */}
<section className="md:py-16 py-8 px-8">
  <div className="container mx-auto">
    <div className="grid grid-cols-1 md:grid-cols-4 gap-8 mx-8">

      {/* First Column */}
      <div className="flex flex-col space-y-8 col-span-2 md:col-span-2 order-1 md:order-none">

        <div className="relative">
          <img
            src={whyUs1}
            alt="24/7 Pickup & Drop-Off and Chat Support"
            className="w-full h-auto object-cover rounded-full md:w-64 md:h-64"
          />
          <div className="absolute inset-0 flex flex-col text-left justify-center text-white bg-black bg-opacity-50 rounded-full p-6">
            <h3 className="text-2xl noto font-semibold">24/7 Pickup & Drop-Off and Chat Support</h3>
            <p className="text-sm leading-tight inter mt-2">We offer 24/7 pickup and drop-off options, along with live chat support for all your needs.</p>
            <span className="absolute bottom-0 right-4 text-8xl font-extralight inter text-green-800 stroke">01</span>
          </div>
        </div>

        <div className="relative order-3 md:order-none">
          <img
            src={whyUs3}
            alt="Free shuttle from YVR"
            className="w-full h-auto object-cover rounded-full md:w-64 md:h-64"
          />
          <div className="absolute text-left inset-0 flex flex-col justify-center text-white bg-black bg-opacity-50 rounded-full p-6">
            <h3 className="text-2xl noto font-semibold">Free shuttle from YVR</h3>
            <p className="text-sm leading-tight inter mt-2">Free shuttle service to and from Vancouver International Airport (YVR) with every rental.</p>
            <span className="absolute bottom-0 right-4 text-8xl font-extralight inter text-green-800 stroke">03</span>
          </div>
        </div>
      </div>

      {/* Second Column */}
      <div className="flex flex-col justify-between col-span-2 md:col-span-2 md:mt-40 order-2 md:order-none">

        <div className="relative mb-8 md:mb-0">
          <img
            src={whyUs2}
            alt="Child seat rentals"
            className="w-full h-auto object-cover rounded-full md:w-64 md:h-64"
          />
          <div className="absolute text-left inset-0 flex flex-col justify-center text-white bg-black bg-opacity-50 rounded-full p-6">
            <h3 className="text-2xl noto font-semibold">Child seat rentals</h3>
            <p className="text-sm leading-tight inter mt-2">We have a wide range of child seats, accommodating all ages, available for rental with your car.</p>
            <span className="absolute bottom-0 right-4 text-8xl font-extralight inter text-green-800 stroke">02</span>
          </div>
        </div>

        <div className="relative mt-0 md:mt-8 order-4 md:order-none">
          <img
            src={whyUs4}
            alt="Quick key handover"
            className="w-full h-auto object-cover rounded-full md:w-64 md:h-64"
          />
          <div className="absolute text-left inset-0 flex flex-col justify-center text-white bg-black bg-opacity-50 rounded-full p-6">
            <h3 className="text-2xl noto font-semibold">Quick key handover</h3>
            <p className="text-sm leading-tight inter mt-2">We provide fast key handover to get you on the road quickly, so you spend less time waiting and more time enjoying your trip.</p>
            <span className="absolute bottom-0 right-4 text-8xl font-extralight inter text-green-800 stroke">04</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

        {/* FAQ Section */}
        <section className="md:py-16 py-12 px-8">
          <div className="container mx-auto">
            <h4 className="text-5xl font-light fontColour1 mb-10">FAQ</h4>
            {faqData.map((faq, index) => (
              <FAQItem key={index} 
                       question={faq.question} 
                       answer={faq.answer} />
            ))}
          </div>
        </section>

      </div>
</div>
      <Footer />
    </>
  );
};

export default About;