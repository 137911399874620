import React, { useState } from 'react';
import Navbar from './components/NavBar';
import Footer from './components/Footer';
import { carsData } from './carsData'; 
import '../src/index.css'

import cars3points from '../src/images/cars3points.png';
import point1 from '../src/images/point1.png';
import point2 from '../src/images/point2.png';
import point3 from '../src/images/point3.png';

function Cars() {

  const [selectedFilter, setSelectedFilter] = useState("Compact Car");

  const handleFilterChange = (filter) => {
    setSelectedFilter(filter);
  };

  return (
    <>
    <div className='background-colour'>
      <Navbar />

<div className='max-w-6xl mx-auto flex flex-col justify-center pt-24'>
      <section className="container mx-auto md:py-16 py-12 px-8 background-colour">
        <h1 className="md:text-5xl text-4xl font-light fontColour1 mb-10">OUR CARS</h1>
        <div className="">
          <div className="flex space-x-8 overflow-x-auto whitespace-pre">
            <button
              onClick={() => handleFilterChange("Compact Car")}
              className={`inter text-xl ${
                selectedFilter === "Compact Car" ? "font-bold underline" : ""
              }`}
            >
              Compact Car
            </button>
            <button
              onClick={() => handleFilterChange("Mid-size Car")}
              className={`inter text-xl ${
                selectedFilter === "Mid-size Car" ? "font-bold underline" : ""
              }`}
            >
              Mid-size Car
            </button>
            <button
              onClick={() => handleFilterChange("Premium Car")}
              className={`inter text-xl ${
                selectedFilter === "Premium Car" ? "font-bold underline" : ""
              }`}
            >
              Premium Car
            </button>
            <button
              onClick={() => handleFilterChange("VAN")}
              className={`inter text-xl ${
                selectedFilter === "VAN" ? "font-bold underline" : ""
              }`}
            >
              VAN
            </button>
            <button
              onClick={() => handleFilterChange("Compact SUV")}
              className={`inter text-xl ${
                selectedFilter === "Compact SUV" ? "font-bold underline" : ""
              }`}
            >
              Compact SUV
            </button>
            <button
              onClick={() => handleFilterChange("Standard SUV")}
              className={`inter text-xl ${
                selectedFilter === "Standard SUV" ? "font-bold underline" : ""
              }`}
            >
              Standard SUV
            </button>
            <button
              onClick={() => handleFilterChange("Pick-up Truck")}
              className={`inter text-xl ${
                selectedFilter === "Pick-up Truck" ? "font-bold underline" : ""
              }`}
            >
              Pick-up Truck
            </button>
        </div>

          <div className="grid md:grid-cols-3 gap-2 md:gap-16 mt-8 lg:w-9/12 background-colour">
            {carsData
              .filter((car) => car.type === selectedFilter)
              .map((car) => (
                <div key={car.id} className="text-center">
                  <img src={car.imgSrc} alt={""} className="w-full" />
                  <p className="inter mt-2 font-semibold text-xl">{car.name}</p>
                </div>
              ))}
          </div>
        </div>

      </section>

      <section className="md:py-16 py-8 px-8 background-colour">
        <div className="container mx-auto">
            <h2 className="md:text-5xl text-4xl font-light text-center fontColour1 mb-10 mt-10 md:mt-20">Why Choose Us</h2>
            {/* <div className='flex justify-center w-full h-auto'> */}
                <img src={cars3points}
                     alt=""
                     className='w-full h-auto'/>
            {/* </div> */}
        </div>
      </section>

      <section className="md:py-16 py-8 background-colour">   
      <div className="px-10 lg:px-20 grid gap-16 md:gap-12">
        <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-24 gap-3 items-center">
          <img src={point1} alt="Clean and sanitized" className="w-full" />
          <div>
            <h3 className="text-xl lg:text-2xl inter font-normal lg:font-semibold">REASON 1</h3>
            <h4 className="md:text-4xl text-3xl	font-semibold mt-2 md:mt-3 lg:mt-5">Clean & sanitized</h4>
            <p className="text-gray-600 lg:mt-2.5 mt-1 text-lg">
              We clean and sanitize each car after every trip to ensure they are thoroughly prepared for the next customer.
            </p>
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-24 gap-3 items-center background-colour">
          <div className='lg:order-none order-last'>
            <h3 className="text-xl lg:text-2xl inter font-normal lg:font-semibold">REASON 2</h3>
            <h4 className="md:text-4xl text-3xl	font-semibold mt-2 md:mt-3 lg:mt-5">Pet-Friendly</h4>
            <p className="text-gray-600 lg:mt-2.5 mt-1 text-lg">
              Our cars are pet-friendly, so your pets are welcome to enjoy the trip with you.
            </p>
          </div>
          <img src={point2} alt="Pets Friendly" className="w-full" />
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-24 gap-3 items-center background-colour">
          <img src={point3} alt="Child Seats and Boosters" className="w-full" />
          <div>
            <h3 className="text-xl lg:text-2xl inter font-normal lg:font-semibold">REASON 3</h3>
            <h4 className="md:text-4xl text-3xl font-semibold mt-2 md:mt-3 lg:mt-5">Child Seats and Boosters</h4>
            <p className="text-gray-600 lg:mt-2.5 mt-1 text-lg">
              Our cars are equipped to accommodate child seats and booster seats, ensuring your child's safety.
            </p>
          </div>
        </div>
      </div>
    </section>
</div>
      <Footer />
    </div>
    </>
  );
}

export default Cars;