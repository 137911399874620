import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Home';
import About from './About'
import Contact from './Contact';
import Store from './Store';
import Cars from './Cars';
import Turo from './Turo';
import NotFoundPage from './Home';
import PassangerPickUpArea from './PassangerPickUpArea';
import DirectionToParkinglots from './DirectionToParkinglots';


const App = () => {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/store" element={<Store />} />
          <Route path="/cars" element={<Cars />} />
          <Route path="/turo" element={<Turo />} />
          <Route path="*" element={<NotFoundPage/>} />
          <Route path="/passangerPickUpArea" element={<PassangerPickUpArea/>} />
          <Route path="/DirectionToParkinglots" element={<DirectionToParkinglots/>} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
