import "./index.css";
import { ReactComponent as Logo } from "../src/logo.svg";
import { useEffect, useState } from "react";
import { Slide } from "react-slideshow-image";
import 'react-slideshow-image/dist/styles.css';
import Footer from "./components/Footer";

import internationalPickUpArea from '../src/images/passangerPickUps/internationalPickUpArea.jpg';
import demosticPickUpArea from '../src/images/passangerPickUps/domesticPickUpArea.jpg';
import international1 from '../src/images/passangerPickUps/international1.jpg';
import international2 from '../src/images/passangerPickUps/international2.jpg';
import international3 from '../src/images/passangerPickUps/international3.jpg';
import international4 from '../src/images/passangerPickUps/international4.jpg';

import demostic1 from '../src/images/passangerPickUps/demostic1.png';
import demostic2 from '../src/images/passangerPickUps/demostic2.png';
import demostic3 from '../src/images/passangerPickUps/demostic3.png';
import demostic4 from '../src/images/passangerPickUps/demostic4.png';
import demostic5 from '../src/images/passangerPickUps/demostic5.png';
import demostic6 from '../src/images/passangerPickUps/demostic6.png';

function PassangerPickUpArea() {

    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const scrollToSection = (sectionId) => {
            const targetSection = document.getElementById(sectionId);
            if (targetSection) {
                window.scrollTo({
                    top: targetSection.offsetTop,
                    behavior: "smooth",
                });
            }
        };
        window.scrollToSection = scrollToSection;

        const handleScroll = () => {
            if (window.scrollY > 300) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    useEffect(() => {
        const scrollToSection = (sectionId) => {
            const targetSection = document.getElementById(sectionId);
            if (targetSection) {
                window.scrollTo({
                    top: targetSection.offsetTop,
                    behavior: "smooth",
                });
            }
        };
        window.scrollToSection = scrollToSection;
    }, []);

    return (
        <div className="min-h-screen bg-gray-100 text-gray-800">
            <header>
                <div className="container mx-auto flex items-center justify-between p-4">
                    <div className="flex flex-row items-center">
                        <Logo />
                        <p className={`rajdhani font-semibold text-2xl pl-2 leading-none`}>
                            KOUSEKI <br /> AUTO RENTAL
                        </p>
                    </div>
                    <nav>
                        <ul className="flex space-x-4">
                            <li>
                                <a href="/" className="inter pl-7 text-base" target='_blank'>
                                    Home
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </header>

            <div className="container mx-auto max-w-6xl mt-12">
                <div className="flex justify-center">
                <button
                    className="inter my-2 text-xl font-extralight w-52 h-14 border border-white relative overflow-hidden group bg-slate-900 hover:text-black hover:bg-white text-white transition duration-300"
                    onClick={() => window.scrollToSection("InternationalFlights")}
                >
                <span className="relative z-10">
                    International Flights
                </span>
                <span className="absolute inset-0 bg-gray-100 text-black transform translate-y-full transition-transform duration-300 ease-in-out group-hover:translate-y-0"></span>
                </button>

                <button
                    className="inter my-2 text-xl font-extralight w-52 h-14 border border-white relative overflow-hidden group bg-slate-900 hover:text-black hover:bg-white text-white transition duration-300"
                    onClick={() => window.scrollToSection("DemosticFlights")}
                >
                <span className="relative z-10">
                    Domestic Flights
                </span>
                <span className="absolute inset-0 bg-gray-100 text-black transform translate-y-full transition-transform duration-300 ease-in-out group-hover:translate-y-0"></span>
                </button>
                </div>
            </div>

            <div id="InternationalFlights" className="py-16 px-8">
                <p className="text-center md:text-2xl text-xl inter font-light pb-6">How to get to the passenger pick up area - <span className="font-medium">International flights</span></p>
                <Slide autoplay={false}>
                    <div className="">
                        <img className="rounded-2xl" src={internationalPickUpArea} />
                        <span className="text-base md:text-2xl lg:text-3xl font-light inter">This is your destination: passenger pick up area for international flights! <br/> (swipe to right to see the next step!)</span>
                    </div>
                    <div className="">
                        <img className="rounded-2xl" src={international1} />
                        <span className="text-base md:text-xl lg:text-3xl font-light inter">Step 1: Exit the building through the door</span>
                    </div>
                    {/* <div className="">
                        <img src={international2} />
                        <span className="text-base md:text-xl font-light inter">Step 2: Continue straight ahead!</span>
                    </div> */}
                    <div className="">
                        <img className="rounded-2xl" src={international3} />
                        <span className="text-base md:text-xl lg:text-3xl font-light inter">Step 2: Follow the signs to 'Passenger Pick-Up' on the board, continue straight ahead, and turn left at the Japadog Food Truck</span>
                    </div>
                    {/* <div className="">
                        <img src={international4} />
                        <span className="text-base md:text-xl font-light inter">Step 4: Turn left at Japadog Food Truck</span>
                    </div> */}
                </Slide>
            </div>

            <div id="DemosticFlights" className="py-16 px-8">
                <p className="text-center md:text-2xl text-xl inter font-light pb-6">How to get to the passenger pick up area - <span className="font-medium">Demostic flights</span></p>
                <Slide autoplay={false}>
                    <div className="">
                        <img className="rounded-2xl" src={demosticPickUpArea} />
                        <span className="text-base md:text-xl lg:text-3xl font-light inter">This is your destination: passenger pick up area for domestic flights! <br/> (swipe to right to see the next step!)</span>
                    </div>
                    <div className="">
                        <img className="rounded-2xl" src={demostic1} />
                        <span className="text-base md:text-xl lg:text-3xl font-light inter">Step 1: Follow the sign to Passenger Pickup on the board after you have claimed your luggage</span>
                    </div>
                    <div className="">
                        <img className="rounded-2xl" src={demostic2} />
                        <span className="text-base md:text-xl lg:text-3xl font-light inter">Step 2: Find this escalator under this sign</span>
                    </div>
                    {/* <div className="">
                        <img src={demostic3} />
                        <span className="text-base md:text-xl font-light inter">Step 3: Pass the Information Center</span>
                    </div> */}
                    <div className="">
                        <img className="rounded-2xl" src={demostic4} />
                        <span className="text-base md:text-xl lg:text-3xl font-light inter">Step 3: Take the escalator to go downstairs</span>
                    </div>
                    <div className="">
                        <img className="rounded-2xl" src={demostic5} />
                        <span className="text-base md:text-xl lg:text-3xl font-light inter">Step 4: Continue going straight!</span>
                    </div>
                    <div className="">
                        <img className="rounded-2xl" src={demostic6} />
                        <span className="text-base md:text-xl lg:text-3xl font-light inter">Step 5: Exit through the door</span>
                    </div>
                </Slide>
            </div>

            <Footer />


            {isVisible && (
                <button 
                    onClick={scrollToTop} 
                    className="fixed bottom-4 right-4 footerColour text-white p-3 rounded-full shadow-lg transition-opacity duration-300 hover:bg-white hover:text-black">
                    ↑
                </button>
            )}
        </div>
    )
}

export default PassangerPickUpArea;