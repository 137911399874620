import React from 'react';
import "../../src/index.css"
import { useState, useEffect } from 'react';
import {ReactComponent as FooterLogo} from "../images/logo.svg"
import {ReactComponent as ContactIcon} from "../images/contactIcon.svg"

import {ReactComponent as Instagram} from "../images/instagram.svg"
import {ReactComponent as Facebook} from "../images/facebook.svg"
import {ReactComponent as Linkedin} from "../images/linkedin.svg"
import {ReactComponent as Tiktok} from "../images/tiktok.svg"

const Footer = () => {
  const [hoveredIcon, setHoveredIcon] = useState(null);
  useEffect(() => {
    const scrollToSection = (sectionId) => {
      const targetSection = document.getElementById(sectionId);
      if (targetSection) {
        window.scrollTo({
          top: targetSection.offsetTop,
          behavior: "smooth",
        });
      }
    };
    window.scrollToSection = scrollToSection;
  }, []);


  return (
    <footer className="footerColour text-white pt-24 pb-12 mt-20">
      <div className="container mx-auto flex flex-col px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 justify-between px-4 md:px-20">

          <div className="space-y-4">
            <h2 className="inter text-4xl font-extralight">CONTACT</h2>
            <div className="flex flex-row gap-12">
              <div>
                <p className="inter text-lg font-light">Monday</p>
                <p className="inter text-lg pt-1 font-light">Tuesday - Friday</p>
                <p className="inter text-lg pt-1 font-light">Holidays</p>
              </div>
              <div>
                <p className="inter text-lg font-light">Closed</p>
                <p className="inter text-lg pt-1 font-light">9:00 - 6:00</p>
                <p className="inter text-lg pt-1 font-light">9:00 - 6:00</p>
              </div>
            </div>
          </div>

          <div className="space-y-6 flex flex-col">
            {/* <div className="space-y-4 flex flex-row items-center gap-8">
              <p className="inter text-lg font-normal">Contact Form</p>
          
                <hr className=" flex-grow border-gray-300 my-2" />
                  <a href="/contact" className="hover:text-gray-300"><ContactIcon /></a>
                 <p className="inter text-lg font-normal">Call Us</p>
                 <p className="inter text-lg font-normal">+1 (604) 355 - 8878</p>
              </div> */}
              <div className='flex flex-row gap-4 items-end'>
                <div>
                  <p className="inter text-base	font-light">Email</p>
                  <p className="inter text-lg font-normal ">info@kousekiautorental.com</p>
                </div>
                  <a href="/contact" className=""><ContactIcon /></a>
              </div>
              <div>
                <p className="inter text-base font-light">Call Us</p>
                <p className="inter text-lg font-normal ">+1 (604) 355 - 8878</p>
              </div>
            </div>
          </div>

          <hr className="border-gray-300 my-16" />
  
          <div className="space-y-4 grid grid-cols-1 justify-between md:grid-cols-2 px-4 md:px-20">
            <div className="flex items-center space-x-4">
              <FooterLogo />
              <div className='flex flex-col'>
                <p className='rajdhani text-xl'>KOUSEN AUTO RENTAL</p>
                <p className='inter text-sm'>4380 Agar Drive #214 Richmond, BC <br/>
                    V7B 1A3, Canada
                </p>

                <div className="flex space-x-6 mt-8">
                  <a href="https://www.instagram.com/kouseki_auto_rental/"
                     target='_blank'
                     className={`relative inline-block transition-transform duration-300 ease-in-out ${hoveredIcon === 'instagram' && 'transform -translate-y-2'}`}
                     onMouseEnter={() => setHoveredIcon('instagram')}
                     onMouseLeave={() => setHoveredIcon(null)}><Instagram /></a>
                  <a href="https://www.facebook.com/people/Kouseki-Auto-Rental-Company/61565112096810/"
                     target='_blank'                    
                    className={`relative inline-block transition-transform duration-300 ease-in-out ${hoveredIcon === 'facebook' && 'transform -translate-y-2'}`}
                    onMouseEnter={() => setHoveredIcon('facebook')}
                    onMouseLeave={() => setHoveredIcon(null)}><Facebook /></a>
                  <a href="https://linkedin.com/company/kouseki-auto-rental"       
                    target="_blank"             
                    className={`relative inline-block transition-transform duration-300 ease-in-out ${hoveredIcon === 'linkedin' && 'transform -translate-y-2'}`}
                    onMouseEnter={() => setHoveredIcon('linkedin')}
                    onMouseLeave={() => setHoveredIcon(null)}><Linkedin /></a>
                </div>
              </div>
            </div>
            <div className="space-x-8">
              <a href="/" className="hover:text-gray-300">Home</a>
              <a href="/about" className="hover:text-gray-300">About</a>
              <a href="/cars" className="hover:text-gray-300">Cars</a>
              <a href="/contact" className="hover:text-gray-300">Contact</a>
            </div>
          </div>
        </div>
    </footer>
  );
};

export default Footer;