import React from 'react';
import { carListings } from '../src/turoCarData'; 
import Footer from './components/Footer';
import { ReactComponent as Logo } from "../src/logo.svg";
import "./index.css"

function Turo() {
  return (
    <div className="min-h-screen bg-gray-100 text-gray-800">
      <header className="bg-white shadow">
        <div className="container mx-auto flex items-center justify-between p-4">
          <div className="flex flex-row items-center">
            <Logo />
            <p className={`rajdhani font-semibold text-2xl pl-2 leading-none`}>
              KOUSEKI <br /> AUTO RENTAL
            </p>
          </div>
          <nav>
            <ul className="flex space-x-4">
              <li>
              <a href="/" className="inter pl-7 text-base" target='_blank'>
                  Home
                </a>
                <a href="https://turo.com/us/en/drivers/28628997" className="inter pl-7 text-base"  target='_blank'>
                  Turo book
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </header>

      <main className="container mx-auto px-4 py-8">
        <h1 className="text-2xl font-bold mb-4">
          Exclusive Car Rental Deals - don't miss out, book now!
        </h1>
        <p className="mb-1 font-sm">
          Easy Pick-Up right at YVR Vancouver International Airport!
        </p>
        <p className="mb-1 font-sm mb-4">
          We are located at the South Terminal, and you are welcome to take a
          free shuttle bus from the main terminal to our location!
        </p>
        <p className="text-xl font-bold">For a lower price - book directly through us!</p>

        <section className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 mt-12">

          {carListings.map((car, index) => (
            <div key={index} className="car bg-white shadow p-4 rounded transition-shadow duration-300 shadow-[3px_2px_25px_4px_rgba(0,0,0,0.2)] hover:shadow-[10px_10px_0px_rgba(140,163,160,1.0)] hover:border-[1px] hover:border-solid hover:border-[rgba(140,163,160,1.0)]">
              <a href={car.link} target="_blank" rel="noopener noreferrer">
                <img
                  src={car.imageUrl}
                  alt={car.name}
                  className="h-40 w-full object-cover mb-4 inter"
                />
                <h2 className="text-lg pb-2 font-semibold noto">{car.name}</h2>
                <p className='text-xs noto'>Year: {car.year}</p>
                <p className='text-xs noto'>{car.transmission}</p>
              </a>
            </div>
          ))}
        </section>
      </main>
        <Footer />
    </div>
  );
}

export default Turo;