import { useState, useEffect } from "react";
import { ReactComponent as Logo } from "../images/logo.svg";
import "../../src/index.css";
import '../index.css';
import { useInView } from 'react-intersection-observer'; 

export default function NavBar() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [showNavbar, setShowNavbar] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [isAtTop, setIsAtTop] = useState(true);
  const [navBarBgColor, setNavBarBgColor] = useState("bg-transparent");

  const isHomePage = window.location.pathname === "/";

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const { ref: navBarRef, inView: navBarRefInView } = useInView({ triggerOnce: true, threshold: 0.1 });

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (menuOpen) {
        setNavBarBgColor("bg-transparent"); 
      } else {
        if (currentScrollY === 0) {
          setIsAtTop(true);
          setNavBarBgColor("bg-transparent"); 
        } else {
          setIsAtTop(false);
          setNavBarBgColor("bg-[#FAFEFF]"); 
        }
      }

      if (!menuOpen && currentScrollY > lastScrollY && currentScrollY > 100) {
        setShowNavbar(false); 
      } else {
        setShowNavbar(true); 
      }

      setLastScrollY(currentScrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY, menuOpen]);

  return (
    <>
      <div>
        <div className={`flex flex-row justify-between px-4 lg:px-20 md:px-8 pt-6 transition-transform duration-300 ${showNavbar ? 'translate-y-0' : '-translate-y-full'} fixed top-0 left-0 right-0 z-50`}>
          
          {!menuOpen && (
            <a href="/">
              <div className="flex flex-row items-center">
                <Logo />
                <p className={`rajdhani font-semibold text-2xl pl-2 leading-none ${isHomePage && isAtTop ? "text-white" : "text-black"}`}>
                  KOUSEKI <br /> AUTO RENTAL
                </p>
              </div>
            </a>
          )}

          {!menuOpen && (
            <div ref={navBarRef} className={`flex flex-row ${navBarRefInView ? 'animate__animated animate__fadeInUp' : 'opacity-0'}`}>
              <div className="hidden lg:flex flex-row pr-8 pt-3">
                <a href="/" className={`inter pl-7 text-base ${isHomePage && isAtTop ? "text-white" : "text-black"}`}>Home</a>
                <a href="/about" className={`inter pl-7 text-base ${isHomePage && isAtTop ? "text-white" : "text-black"}`}>About</a>
                <a href="/cars" className={`inter pl-7 text-base ${isHomePage && isAtTop ? "text-white" : "text-black"}`}>Cars</a>
                <a href="/contact" className={`inter pl-7 text-base ${isHomePage && isAtTop ? "text-white" : "text-black"}`}>Contact</a>
              </div>
            </div>
          )}

          {/* Menu Button */}
          <div className="flex flex-col items-center justify-center fixed top-6 right-6 z-50">
            <button onClick={toggleMenu} className="w-10 h-10 flex flex-col items-center justify-center">
              {/* Line 1 */}
              <div className={`w-12 menuLineHeight transition-transform duration-300 ${menuOpen ? "bg-white rotate-45 translate-y-1.5" : `bg-${isHomePage && isAtTop ? "white" : "black"}`}`}></div>
              {/* Line 2 */}
              <div className={`w-12 menuLineHeight mt-2 transition-transform duration-300 ${menuOpen ? "bg-white -rotate-45 -translate-y-1.5" : `bg-${isHomePage && isAtTop ? "white" : "black"}`}`}></div>
            </button>
            <span className={`inter text-sm font-normal ${menuOpen ? "text-white" : (isHomePage && isAtTop ? "text-white" : "text-black")}`}>
              {menuOpen ? "CLOSE" : "MENU"}
            </span>
          </div>
        </div>

        {menuOpen && (
          <div className="pl-24 fixed top-0 left-0 w-full h-full footerColour bg-opacity-90 z-40 flex flex-col justify-center">
            <a href="/" className="link block py-4 inter font-extralight text-white text-2xl md:text-4xl">01. Home</a>
            <a href="/about" className="link block py-4 inter font-extralight text-white text-2xl md:text-4xl mt-2 md:mt-4">02. About</a>
            <a href="/cars" className="link block py-4 inter font-extralight text-white text-2xl md:text-4xl mt-2 md:mt-4">03. Cars</a>
            <a href="/contact" className="link block py-4 inter font-extralight text-white text-2xl md:text-4xl mt-2 md:mt-4">04. Contact</a>
          </div>
        )}
      </div>
    </>
  );
}