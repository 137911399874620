import React from 'react';
import Navbar from './components/NavBar';
import Footer from './components/Footer';

const Store = () => {
  return (
    <>
    <div className="background-colour">
        <Navbar />      
  
  <div className='max-w-6xl mx-auto flex flex-col justify-center md:py-16 py-8 px-8'>
        <div className="container mx-auto">
        <section className="flex md:flex-row flex-col-reverse gap-8 justify-between pt-24">
          <div>
            <h2 className="md:text-5xl text-4xl font-light fontColour1">STORE</h2>
            <p className="text-gray-600 text-xl md:text-2xl mb-2 md:mb-4 font-light">Visit Us</p>

            <div className="">
              <h1>Kouseki Auto Rental Inc.</h1>
              <p>4380 Agar Drive #214 Richmond, BC <br />
                V7B 1A3, Canada</p>
            </div>
          </div>
          <div>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2607.9645881030065!2d-123.17563732323333!3d49.18225857137784!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54860b2f5ee05d3b%3A0x9621e6b4d4a7764f!2s4380%20Agar%20Dr%20%23214%2C%20Richmond%2C%20BC%20V7B%201A3!5e0!3m2!1sen!2sca!4v1726622937093!5m2!1sen!2sca"
              style={{ width: '400px', height: '230px', border: '0' }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Direction to the store"
            ></iframe>
          </div>
        </section>

        <section className="py-16 flex flex-col container mx-auto">
          <div>
            <h2 className="text-5xl font-light mb-4 fontColour1">PROFILE</h2>
          </div>
          <div className="space-y-4">
          <div className="bg-white p-8 rounded-md w-full">
        <div className=" border-gray-300">
          <div className="flex md:flex-row flex-col justify-between py-3 border-b border-gray-300">
            <span className="text-lg text-gray-600 inter">Store</span>
            <span className="text-lg text-right font-normal inter text-gray-800">Kouseki Auto Rental Inc.</span>
          </div>
          <div className="flex md:flex-row flex-col justify-between py-3 border-b border-gray-300">
            <span className="text-lg text-gray-600 inter">Access</span>
            <span className="text-lg text-right font-normal text-gray-800 inter">Free shuttle from YVR</span>
          </div>
          <div className="flex md:flex-row flex-col justify-between py-3 border-b border-gray-300">
            <span className="text-lg text-gray-600 inter">Address</span>
            <span className="text-lg text-right font-normal text-gray-800 inter">4380 Agar Dr</span>
          </div>
          <div className="flex md:flex-row flex-col justify-between py-3 border-b border-gray-300">
            <span className="text-lg text-gray-600 inter">Hours</span>
            <span className="text-right">
              <div className='text-lg  text-gray-800  font-normal inter'>Monday <span className="text-lg inter ml-4 text-gray-800">Closed</span></div>
              <div className='text-lg  text-gray-800  font-normal inter'>Tuesday - Sunday <span className="text-lg inter ml-4 text-gray-800">9:00 - 6:00</span></div>
              <div className='text-lg  text-gray-800  font-normal inter'>Holidays <span className="text-lg inter ml-4 text-gray-800">9:00 - 6:00</span></div>
            </span>
          </div>
          <div className="flex md:flex-row flex-col justify-between py-3 border-b border-gray-300">
            <span className="inter text-lg text-gray-600">Phone number</span>
            <span className="inter text-right text-lg font-normal text-gray-800">+1 (604) 355 - 8878</span>
          </div>
          <div className="flex md:flex-row flex-col justify-between py-5 border-b border-gray-300">
            <span className="inter text-lg text-gray-600">Email</span>
            <span className="inter text-right text-lg font-normal text-gray-800">info@kousekiautorental.com</span>
          </div>
        </div>
      </div>
          </div>
        </section>
      </div>
</div>
      <Footer />
      </div>
    </>
  );
};

export default Store;